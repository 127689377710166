<template>
  <div>
    <div class="box">
      <dfn>颜色</dfn>
      <ul class="color_box">
        <li>
          <p id="color_main"></p>
          主体 #3582B8
        </li>
        <li>
          <p id="color_assist"></p>
          辅助 #A2BB21
        </li>
        <li>
          <p id="color_success"></p>
          成功 #67C23A
        </li>
        <li>
          <p id="color_warning"></p>
          警告 #E6A23C
        </li>
        <li>
          <p id="color_danger"></p>
          错误 #F56C6C
        </li>
        <li>
          <p id="color_function"></p>
          功能按钮 #409EFF
        </li>
      </ul>
    </div>
    <div class="box">
      <dfn>文字</dfn>
      <ul class="text_box">
        <li>
         <span id="text_title_one">一级标题</span>
           加粗 24px 行高34px #000000 每个页面内至多出现一次，页头文字、特殊场景
        </li>
        <li>
          <span id="text_title_two">二级标题</span>
             22px 行高28px #000000 每个页面可以多次出现，小标题、用于弹窗
        </li>
        <li>
          <span id="text_title_three">三级标题</span>
            20px #909399 每个页面可以多次出现，提示性文字，表格头
        </li>
        <li>
          <span id="text_content_one">一类文字</span>
            20px  #303133 部分时候允许加粗 用于一般文字显示，介绍，段落内容，书写主体，主要提示
        </li>
        <li>
          <span id="text_content_two">二类文字</span>
            18px  #303133 用于次级文字显示，补充说明，表格内容
        </li>
        <li>
          <span id="text_content_three">三类文字</span>
            18px  #909399 用于极特殊情况下的文字补充
        </li>
      </ul>
    </div>
    <div class="box">
      <dfn>图标</dfn>
      <ul class="icon_box">
        <li>
          <p class="circular"></p>
          <p class="square"></p>
        </li>
        <!-- <li>
          <p class="circular"></p>
          <p class="square" style="width:58px;height:57px"></p>
        </li> -->
      </ul>
    </div>
    <div class="box">
      <dfn>图片</dfn>
      <ul class="border_box">
        <li>
          
        </li>
      </ul>
    </div>
    <div class="box">
      <dfn>描述列表</dfn>
      <ul class="border_box">
        <li>
          搜索详情位置的描述列表,保持不变
        </li>
      </ul>
    </div>
    <div class="box">
      <dfn>标签切换</dfn>
      全部以此形式处理
      <ul class="border_box">
        <li>
          <el-tabs v-model="activeName" >
            <el-tab-pane label="用户管理" name="first">用123户管理</el-tab-pane>
            <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
            <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
            <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
          </el-tabs>
          
        </li>
      </ul>
    </div>
    <div class="box">
      <dfn>输入框</dfn>
      <p> 输入框前方的label使用专用标签，样式为“一类文字”。文本描述以中文冒号结尾，和输入框之间不设间隔 <br>
        label宽度两种设置方式：1、固定宽度；2、长度自适应，保证显示。由于label自身长度，导致多输入框在一起时，看起来间隔不相同 <br>  
        使用场景  1、搜索筛选 2、表单输入 <br>
        快速搜索：自适应           <br>
        高级搜索：自适应           <br>
        质控面板：自适应           <br>
        室内质控：自适应           <br>
        新增质控：固定宽度         <br>
        室间质评：自适应           <br>
        统计筛选：自适应           <br>
      </p>
      <ul class="input_box">
        <li>
          <span>基础输入框</span>
          直接输入文字，无任何提示或校验 <br>
          机号、UDI、销售、工程师、质控批号、试剂批号、订单编号、发货单号、收货单位、申请人、发货单号、失控标识
          <p>
            <label for="input_main_id" class="label">项目名称：</label>
            <el-input
              placeholder="请输入内容"
              v-model="input"
              id="input_main_id"
              clearable>
            </el-input>
          </p>
        </li>
      </ul>
      <ul class="input_box">
        <li>
          <span>搜索选择框</span>
          部分具有联动效果：省份和市区；质检单产品名称和批号；产品类型选择，产品名称。<br>
          远程搜索和选项搜索都必须在下拉框中选择一个数据，不能不选<br>
          平台、省份、市区、穿刺机、是否厂内、检测项目、样本类型、阴阳性、质检单产品名称、质检单产品批号、产品类型选择，产品名称、告警代码、日志类型、物品类型  医院、经销商
          <p>
            <label for="1231" class="label">下拉选择：</label>
            <el-select
              v-model="value_select"
              placeholder="请选择"
              class="centerSelect"
              filterable
              clearable
              size="medium"
            >
              <el-option
                v-for="item in selectOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </p>
        </li>
        <li>
          <span>时间选择</span>
          不同位置存在不同的时间选择器，目前共三种，建议合并，最好只保留一种。<br>
          时间选择器长度比一遍输入框长，做出合理兼容性
          <p>
            <label class="label">日期范围：</label>
            <el-date-picker
              size="medium"
              id="1232131"
              v-model="value_data"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :clearable="false"
            ></el-date-picker>
          </p>
          <p>
            <label class="label">日期范围：</label>
            <timePickerCloud ref="timePickerCloud" :pickerOptions="pickerOptions" :end_stats_dayOld="end_stats_day" :start_stats_dayOld="start_stats_day" @setTime="setTime" ></timePickerCloud>
          </p>
          <p>
            <label class="label">日期范围：</label>
            <timePicker @setTime="setTime"></timePicker>
          </p>
        </li>
      </ul>
    </div>
    <div class="box">
      <dfn>按钮</dfn>
      <ul class="border_box">
        <li>
          <el-button type="primary">主要按钮</el-button>
          <el-button type="primary" size="medium">中等型号</el-button>
          <el-button type="primary" size="small">小型号</el-button>
          <el-button type="primary" size="mini">超小型号</el-button>
          按钮样式采用element——UI样式，所有按钮位置，只能使用该插件，不可以使用其他标签
        </li>
      </ul>
    </div>
    <div class="box">
      <dfn>echarts图</dfn>
      <ul class="border_box">
        <li>
          折现图
        </li>
        <li>
          饼图
        </li>
      </ul>
    </div>
    <div class="box">
      <dfn>盒子模型</dfn>
      
      <h3>边框圆角</h3>
      只能使用下方三种边框圆角模式
      <ul class="border_box">
        <li>
          <p class="border_4">4px圆角</p>
        </li>
        <li>
          <p class="border_8">8px圆角</p>
        </li>
        <li>
          <p class="border_16">16px圆角</p>
        </li>
      </ul>
      <h3>边距</h3>
      内边距，外边距 以4位的幂方设置，如4,8,16,32,
      <ul class="distance_box">
        <li>
          <p class="distance_4">内4px边距</p>
        </li>
        <li>
          <p class="distance_8">内8px边距</p>
        </li>
        <li>
          <p class="distance_16">内16px边距</p>
        </li>
        <li>
          <p class="distance_32">内32px边距</p>
        </li>
      </ul>
      <!-- <h3>演示区</h3>
      <el-input v-model="inputBoxNum" placeholder="请输入盒子数量" ></el-input>
      <el-input v-model="inputBoxWidth" placeholder="请输入盒子宽度"></el-input>
      <el-input v-model="inputBoxHeigth" placeholder="请输入盒子高度"></el-input>
      <el-input v-model="inputBoxPadding" placeholder="请输入盒子内边距"></el-input>
      <el-input v-model="inputBoxMargin" placeholder="请输入盒子外边距"></el-input>
      <el-button type="primary" size="medium" @click="set_box_style_fun">确定</el-button>
      
      <ul class="boxModel_show_box">
        <li v-for="(item,index) in boxModelShowNum" :key="index">
          <p id="box_item">我是演示盒子{{item}}</p>
        </li>
      </ul> -->
    </div>
    <div class="box">
      <dfn>表单</dfn>
      <h3>对齐方式</h3>
      <ul class="aline_box">
        <li>
          标签左对齐，最长的右侧再增加一段margin
          <img src="https://img.zcool.cn/community/02786d598959c5a8012156035b53f6.png" alt="b686598959c5a801215603110a76.jpg">
        </li>
        <li>
          标签右对齐，
          <img src="https://img.zcool.cn/community/02ff38598959d0a80121560366f1d2.png" alt="3e78598959d0a801215603bda2df.jpg">
        </li>
      </ul>
    </div>
    <div class="box">
      <dfn>提示</dfn>
      采用通知形式，更换组件
      <ul class="tip_box">
        <li>
          <el-button type="primary" size="medium" @click="open_tip1">基础提示</el-button>
          用于大多数位置，使用element-ui插件，
        </li>
        <li>
          <el-button type="primary" size="medium" @click="open_tip2">手动关闭</el-button>
          不会自动关闭，需要手动操作
        </li>
        <li>
          <el-button type="success" size="medium" @click="open_tip3">成功</el-button>
          成功：操作成功、数据加载完成并且数据无异常 10s
        </li>
        <li>
          <el-button type="warning" size="medium" @click="open_tip4">警告</el-button>
          警告：因为某些原因，未返回正确格式数据、无数据  15s
        </li>
        <li>
          <el-button type="danger" size="medium" @click="open_tip5">错误</el-button>
          错误：操作失败 一直显示，自行关闭
        </li>
        <li>
          禁止使用 info 类型，和背景图片相似容易混淆
        </li>
      </ul>
    </div>
    <div class="box">
      <dfn>布局</dfn>
      <ul class="container_box">
        二选一，设定好margin参数
        <li>
          <h3>顶部</h3>
          高度56px自适应
        </li>
        <li>
          <h3>侧边导航栏</h3>
          宽度300px自适应，展示全部内容,把子栏往左移动，子栏右边距进行调整
        </li>
        <li>
          <h3>底部显示</h3>
          页面底部 "腾云管家大数据+AI人工智能版权所有 Copyright2017沪ICP备12044172号-2版本号V1.0.18"在登陆页和首页显示，去除搜索页显示，其他页不显示<br>
          高17 宽设最小值，保证文字显示，字体18，#909399
        </li>
      
      </ul>
    </div>
    
    <div class="box">
      <dfn>弹窗</dfn>
      <ul class="dialog_box">
        <li>
          <el-button type="primary" @click="dialogVisible = true">点击弹窗</el-button>
          基础样式
          <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="30%"
            >
            <span>这是一段信息</span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
          </el-dialog>
        </li>
        <li>
          <el-button type="primary" @click="dialogVisible2=true">点击弹窗</el-button>
          缩减顶部空白 缩减顶部内边距
          <el-dialog
            title="提示"
            :visible.sync="dialogVisible2"
            width="30%"
            custom-class="dialog_global"
            >
            <span>这是一段信息</span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible2 = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible2 = false">确 定</el-button>
            </span>
          </el-dialog>
        </li>
        <li>
          <el-button type="primary" @click="dialogVisible3=true">点击弹窗</el-button>
          修改宽度 不同情况下应用不同宽度
          <el-dialog
            title="提示"
            :visible.sync="dialogVisible3"
            :width="width_dialog"
            >
            <div>
              <el-button type="primary" size="medium" @click="width_dialog='30%'">30%</el-button>
              <el-button type="primary" size="medium" @click="width_dialog='50%'">50%</el-button>
              <el-button type="primary" size="medium" @click="width_dialog='80%'">80%</el-button>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible3 = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible3 = false">确 定</el-button>
            </span>
          </el-dialog>
        </li>
        <li>
          <el-button type="primary" @click="dialogVisible4=true">点击弹窗</el-button>
            超长内容处理 降低 中间内容的上下内边距， 降低底部按钮的内边距
          <el-dialog
            title="提示"
            :visible.sync="dialogVisible4"
            width="30%"
            custom-class="dialog_global_langContent"
            >
            <div id="dialog_content_box">
              <p v-for="(item,index) in dialogList" :key="index">
                这是第{{item}}行，我很长
              </p>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible4 = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible4 = false">确 定</el-button>
            </span>
          </el-dialog>
        </li>
      </ul>
      
    </div>
    <div class="box">
      <dfn>表格</dfn>
      <ul class="table_box">
        <li>
          宽度等距
          <el-table :data="tableData" stripe height="250" v-loading="loading">
            <el-table-column
              prop="order_code"
              label="订单编号"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="dispatch_code"
              label="发货单号"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="doSomeThing(scope.row)">
                  查看
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </li>
        <li>
          设定列宽，屏幕大小自适应,当前列数字超长时，设定最低值，保证所有界面均能正确展示，<br>
          或者长数字隐藏，鼠标放置是显示全部 <br>
          操作栏有固定位置，可以通过Ctrl+滚动鼠标滚轮放大页面方式查看
          <el-table :data="tableData" stripe height="250" v-loading="loading">
            <el-table-column
              prop="order_code"
              label="订单编号"
              align="center"
              :width="fw(100)"
            ></el-table-column>
            <el-table-column
              prop="udi"
              label="udi"
              align="center"
              :width="fw(450,320)"
            ></el-table-column>
          <el-table-column
          prop="udi"
          label="udi"
          align="center"
          :width="fw(450,320)"
        ></el-table-column>
            <el-table-column
              prop="order_code"
              label="订单编号"
              align="center"
              :width="fw(100)"
            ></el-table-column>
            <el-table-column
              prop="udi"
              label="udi"
              align="center"
              :width="fw(100)"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="dispatch_code"
              label="发货单号"
              align="center"
              :width="fw(200)"
            ></el-table-column>
            <el-table-column label="操作" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="doSomeThing(scope.row)">
                  查看
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </li>
      </ul>
    </div>
    <div class="box">
      <div id="container" class="container">
        <button id='click-button' @click="expand">展开收起</button>
        <button id='click-button' @click="retract">收起</button>
        <div id="trigger-section" class="trigger-section" style="display: none;">
          <p>1</p>
          <p>2</p>
          <p>3</p>
          <p>4</p>
          <p>5</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timePickerCloud from '@/components/timePickerCloud.vue';
import timePicker from '@/components/timePicker'

import {flexColumnWidth} from '@/assets/js/adaption.js'
export default {
  components: {timePickerCloud,timePicker},
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      boxModelShowNum: 10,
      width_dialog: "30%",
      dialogList: 80,
      loading: false,
      tableData: [
        {
          order_code: '12312',
          dispatch_code: '123123122',
          udi: '040060120220802202402160525480'
        },
        {
          order_code: '12312',
          dispatch_code: '123123122',
          udi: '35415248574895684512457854158452658475256458'
        },
        {
          order_code: '12312',
          dispatch_code: '123123122',
          udi: '35415248574895684512457854158452658475256458'
        },
      ],
      activeName: '',
      inputBoxNum: 20,
      inputBoxWidth: 200,
      inputBoxHeigth: 200,
      inputBoxPadding: 5,
      inputBoxMargin: 20,
      input: '',
      selectOption: [{
        value: 1,
        label: "选项1"
      },
      {
        value: 2,
        label: "选项2"
      },
      {
        value: 3,
        label: "选项3"
      },],
      value_select: '',
      value_data: '',
      pickerOptions: {}, //日期选择器特有的选项
      start_stats_day: '', //选择的开始时间
      end_stats_day: '', //选择的结束时间

      expandShow:false,
    }
  },
  methods: {
    // 表格宽度
    fw(width,min_width) {
      return flexColumnWidth(width,min_width)
    },
    // 打开消息提示
    open_tip1() {
      this.$message({
        message: '这是一条消息提示',
      });
    },
    // 设置演示盒子参数
    set_box_style_fun() {
      
    },
    open_tip2() {
      this.$message({
        message: '这是一条消息提示',
        duration: 0,
        showClose: true,
      });
    },
    open_tip3() {
      this.$message({
        message: '这是一条成功提示',
        type: 'success',
      });
    },
    open_tip4() {
      this.$message({
        message: '这是一条警告提示',
        type: 'warning',
      });
    },
    open_tip5() {
      this.$message.error('错了哦，这是一条错误消息');
    },
    // 设置时间
    setTime(val) {
      this.start_stats_day = val.start_stats_day?val.start_stats_day.substring(0,4)+'-'+ 
                              val.start_stats_day.substring(4,6)+'-'+ 
                              val.start_stats_day.substring(6,8):'';
      this.end_stats_day =  val.end_stats_day?val.end_stats_day.substring(0,4)+'-'+
                            val.end_stats_day.substring(4,6)+'-'+
                            val.end_stats_day.substring(6,8):'';
      // this.set_time_and_phOption('time');
    },

    // 展开
    expand() {
      const element = document.getElementById('trigger-section');
      let expandTimer = null;
      let offsetHeight = 0;

      // 获取元素总高度
      element.style.display = 'block';
      let height = 0;
      // 先将 display 设置为 block，获取到的 offsetHeight 才是正确的高度，之后才能设置元素高度
      offsetHeight = element.offsetHeight;
      const stepHeight = offsetHeight / 30;
      element.style.height = height + 'px';

      expandTimer = setInterval(() => {
          height += stepHeight;
          if (height >= offsetHeight) {
              clearInterval(expandTimer);
              element.style = null;
              return;
          }
          element.style.height = height + 'px';
      }, 10);

    },
    // 收起
    retract() {
      const element = document.getElementById('trigger-section');
      let collapseTimer = null;
      let offsetHeight = 0;
      offsetHeight = element.offsetHeight;
      let height = offsetHeight;

      const stepHeight = offsetHeight / 30;
      element.style.height = height + 'px';

      collapseTimer = setInterval(() => {
          height -= stepHeight;
          if (height <= 0) {
              clearInterval(collapseTimer);
              element.style.height = null;
                // display 设为 null，要将元素隐藏
              element.style.display = 'none';
              return;
          }
          element.style.height = height + 'px';
      }, 10);

    }
    
  }
}
</script>

<style lang="scss" scoped>
  .box {
    border: 1px solid #000;
    border-radius: 4px;
    padding: 10px;
    margin: 10px;
    color: #303133;
  }
  ul {
    li {
      margin-top: 10px;
    }
  }
  .color_box {
    @include flex;
    li {
      width: 10%;
      p {
        width: px(100);
        height: px(100);
        background-color: #000;
      }
      #color_main {
        background-color: #3582B8;
      }
      #color_assist {
        background-color: #A2BB21;
      }
      #color_success {
        background-color: #67C23A;
      }
      #color_warning {
        background-color: #E6A23C;
      }
      #color_danger {
        background-color: #F56C6C;
      }
      #color_function {
        background-color: #409EFF;
      }
      #color_text {
        background-color: #67C23A;
      }
    }
  }
  .text_box {
    li {
      #text_title_one {
        font-weight: bold;
        font-size: px(24);
        line-height: px(34);
        color: #000000;
      }
      #text_title_two {
        font-size: px(22);
        line-height: px(28);
        color: #000000;
      }
      #text_title_three {
        font-size: px(20);
        line-height: px(22);
        color: #909399;
      }
      #text_content_one {
        font-size: px(20);
        line-height: px(22);
        color: #303133;
      }
      #text_content_two {
        font-size: px(18);
        line-height: px(20);
        color: #303133;
      }
      #text_content_three {
        font-size: px(18);
        line-height: px(20);
        color: #909399;
      }
    }
  }
  .icon_box {
    li {
      @include flex();
      p {
        margin-left: px(50);
      }
      .circular {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: pink;
      }
      .square {
        width: 60px;
        height: 60px;
        background-color: pink;
      }
    }
  }
  .container_box {
    li {
      span:first-child {
        font-weight: bold;
        font-size: px(24);
        line-height: px(34);
        color: #000000;
      }
    }
  }
  .input_box {
    li {
      p{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-width: px(320);
        margin-bottom: px(10);
        .label {
          text-align: right;
          white-space: nowrap;
          font-size: px(18);
          line-height: px(22);
          color: #303133;
        }
        ::v-deep .calendarDate{
          width: px(180);
         }
      }
    }
  }
  .table_box {
    li {
      margin-top: 10px;
      .table_col_lang {
        width: px(100);
        overflow: hiddenx;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .table_col_lang2 {
        overflow: hiddenx;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .table_col_lang2:hover {
        text-overflow:inherit; 
        overflow:visible; 
      }
    }
  }
  .border_box {
    @include flex;
    li {
      margin-left: 20px;
      p {
        height: px(200);
        width: px(200 );
        background-color: pink;
      }
    }
    .border_4 {
      border-radius: px(4);
    }
    .border_8 {
      border-radius: px(8);
    }
    .border_16 {
      border-radius: px(16);
    }
  }
  .aline_box {
    
  }
  .distance_box {
    @include flex;
    li {
      margin-left: 20px;
      p {
        background-color: pink;
      }
      .distance_4 {
        width: px(192);
        height: px(192);
        padding: px(4);
      }
      .distance_8 {
        width: px(184);
        height: px(184);
        padding: px(8);
      }
      .distance_16 {
        width: px(168);
        height: px(168);
        padding: px(16);
      }
      .distance_32 {
        width: px(136);
        height: px(136);
        padding: px(32);
      }
    }
  }
  .boxModel_show_box {
    @include flex;
    li {
      margin-left: px(5);
      background-color: pink;
      p {
        width: 200px;
        height: 200px;
        border: 10px;
      }
    }
  }
  ::v-deep .dialog_global {
    .el-dialog__body {
      padding-top: 0 !important;
    }
  }
  ::v-deep .dialog_global_langContent {
    margin-top: 5vh !important;
    .el-dialog__body {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      div:first-child {
        max-height: 70vh;
        overflow: auto;
      }
    }
    .el-dialog__footer {
      padding: px(10) px(10) px(10);
    }
  }



  .flex-container {
    display: flex;
    gap: 20px;
    height: 500px; 
    padding-left: 40px;
  }
  
  .container { 
    width: 240px;
    height: 340px;
    color: #000;
    border: 1px solid #000;
    padding: 20px;
    text-align: center;
  }
  
  .trigger {
    background-color: #ccc;
    overflow: hidden;
    transition: all 0.3s;
    height: 0;
    width: 100%;
  }
  
  p {
    padding: 10px;
  }

  .trigger-section {
    background-color: #ccc;
    min-height: 0;
    overflow: hidden;
  }
 
</style>>

